<template>
    <div>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
        <el-breadcrumb-item to="/cash-out-list">申请提现</el-breadcrumb-item>
        <el-breadcrumb-item>提示</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider class="div_der"></el-divider>
      <div class="details">
        <img src="../../assets/successIcon.jpg">
        <p class="title-success">申请提交成功！</p>
        <p class="remarks">提现后预计3个工作日内到账，若未及时到账，请联系海葵分贝客服人员</p>
        <ul>
          <li><span class="label">收款账户</span><span class="con"><img :src="obj.url">{{obj.bankName}} ({{obj.bankCard.substring(obj.bankCard.length - 4)}})</span></li>
          <li><span class="label">应到账金额</span><span class="con">{{$toFindexFn(obj.money)}}元</span></li>
        </ul>
        <footer>
         <el-button @click="$router.replace('/income')">返回我的收益</el-button>
          <el-button class="but" @click="$router.replace('/cash-out-list')">继续提现</el-button>
        </footer>
      </div>
    </div>

</template>

<script>

export default {
  name: "FeedbackPage",
  data(){
    return {
      obj:JSON.parse(this.$route.query.data)
    }
  },
  mounted() {
  },
  created() {
    // console.log(JSON.parse(this.$route.query.data))
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/works.scss";

.div_der {
  height: 1px;
  border: 1px solid #f5f5f5;
  margin-top: 21px;
  margin-bottom:116px;
}

.details {
  width: 443px;
  text-align: center;
  margin: 0 auto;
  * {
    padding: 0px;
    margin: 0px;
    list-style: none;

  }

  > img {
    width: 100px;
    height: 100px;
  }

  > .title-success {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: CENTER;
    color: #333333;
    margin-bottom:20px;
  }

  > .remarks {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #666660;
    margin-bottom: 32px;
  }
  li {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    span{
      display: inline-block;
    }
    .label {
      color: #999999;
    }

    .con {
      color: #666666;
      img{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }
  ul {
    padding-bottom:24px;
    border-bottom: 1px solid #f4f4f4;
    li:nth-child(1) {
      margin-bottom: 14px;
    }
  }
 footer{
   margin-top: 30px;
   .el-button{
     width: 124px;
     height: 40px;
     font-size: 14px;
     font-family: PingFang SC, PingFang SC-Regular;
     font-weight: 400;
     color: #666666;
     border-radius: 3px;
   }
   .but{
     color: #ffffff;
     background: #ff004d;
     margin-left: 40px;
   }
 }
}

</style>
